import request from "@/core/services/axios";

// 选做题处理列表
export function exammarkChooseList(query) {
  return request({
    url: "/exam/exammark/choose/list",
    method: "get",
    params: query,
  });
}
// 选做题处理-导出小题学生名单
export function exammarkExcelChoose(query) {
  return request({
    url: "/exam/exammark/excel/choose",
    method: "get",
    params: query,
  });
}

// 选做题处理列表
export function exammarkChoose(query) {
  return request({
    url: "/exam/exammark/choose",
    method: "put",
    params: query,
  });
}
