<template>
  <div class="ExamChooseQuestion">
    <ExamDetailsHeader
      :name="name + ' / ' + subjectName + ' / 阅卷分配 / 选做题处理'"
    >
    </ExamDetailsHeader>
    <div class="question-list-box">
      <el-table :data="chooseQuestionList" border style="width: 100%">
        <el-table-column
          prop="questionNum"
          label="题目名称"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="blank" label="空白题" width="180" align="center">
        </el-table-column>
        <el-table-column
          prop="pending"
          label="待处理"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pending"
          label="处理方式1:设置处理人"
          align="center"
        >
          <template slot-scope="scope">
            <span style="margin-right: 5px">{{ scope.row.teacherName }}</span>
            <el-button type="text" @click="showTeacher(scope.row)">
              设置
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="pending"
          label="处理方式2:批量指定"
          align="center"
        >
          <template slot-scope="scope">
            <!-- designQuestionNum -->
            <span style="margin-right: 5px">{{
              scope.row.designQuestionNum
            }}</span>
            <el-button type="text" @click="showAssign(scope.row)"
              >选题</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="pending" label="导出小题学生名单" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="exportStudent(scope.row, 1)"
              >导出</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="pending"
          label="导出异常涂抹学生名单"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="exportStudent(scope.row, 2)"
              >导出</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="question-tips">
      学生未涂抹的选做题，有两种处理方式：1)设置处理人，处理人登录平台对所有未涂抹的题手动指定。2)批量指定到某题。两种方式您可以根据需要选择使用
    </div>
    <!-- 添加阅卷教师 -->
    <el-dialog
      title="添加阅卷教师"
      :visible.sync="teacherDialogVisible"
      width="700px"
    >
      <div class="set-teacher-box">
        <div class="teacher-search-box">
          <div class="search-item">
            学段：
            <el-select
              v-model="modelSearch.level"
              placeholder="请选择学段"
              clearable
              @change="levelChange"
            >
              <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <!-- yearHignOptions yearMiddleOptions -->
            年级：
            <el-select
              v-if="modelSearch.level == 2"
              v-model="modelSearch.year"
              placeholder="请选择年级"
              clearable
              @change="gradeChange"
            >
              <el-option
                v-for="(item, key) in yearMiddleOptions"
                :key="key"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              v-else
              v-model="modelSearch.year"
              clearable
              placeholder="请选择年级"
              @change="gradeChange"
            >
              <el-option
                v-for="(item, key) in yearHignOptions"
                :key="key"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item ts-item">
            班级：
            <el-select
              v-model="modelSearch.classNum"
              clearable
              placeholder="请选择"
              @change="selectChange"
            >
              <el-option
                v-for="item in classOptions"
                :key="item.id"
                :label="item.classNum"
                :value="item.classNum"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            科目：
            <el-select
              v-model="modelSearch.subjectId"
              clearable
              placeholder="请选择"
              @change="selectChange"
            >
              <el-option
                v-for="item in subjectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            姓名：
            <el-input
              v-model="modelSearch.name"
              class="search-input"
              placeholder="请输入搜索内容"
              @keyup.enter.native="schoolteacherlistv2"
            >
              <i
                slot="suffix"
                class="el-icon-search el-input__icon"
                @click="schoolteacherlistv2"
              >
              </i
            ></el-input>
          </div>

          <div class="check-teacher-list">
            <div class="check-list edit-scroll-style">
              <el-checkbox-group v-model="checkTeacher">
                <el-checkbox
                  v-for="(v, i) in teacherList"
                  :key="i"
                  :label="v.teacherId"
                >
                  {{ v.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="checkTeacher = ''">取消已选中教师</el-button> -->
        <el-button @click="teacherDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitTeacher"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 选择指定到哪一题 -->
    <el-dialog
      title="选择指定到哪一题"
      :visible.sync="assignDialogVisible"
      width="520px"
    >
      <div class="tips">
        <span>!</span>注意：已处理或已批量指定题目将无法被重新指定，请谨慎操作
      </div>
      <div style="margin: 24px 0">将学生未涂抹的选择题批量指定到：</div>
      <div>
        <el-radio-group v-model="checkQuestion">
          <el-radio v-for="(v, i) in editQuestionList" :key="i" :label="v">{{
            v
          }}</el-radio>
        </el-radio-group>
        <!-- editQuestionList -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="assignDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitQuestion"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ExamDetailsHeader from "./component/ExamDetailsHeader";
import {
  exammarkChooseList,
  exammarkExcelChoose,
  exammarkChoose,
} from "@/core/api/exam/examChooseQuestion";
import { schoolteacherlistv2 } from "@/core/api/school/schoolteacher";
import {
  // scoreRulesList,
  // decimalDigits,
  // digitsMethod,
  // stepSize,
  // scoreMethod,
  subjectOptions,
  levelOptions,
} from "@/core/util/constdata";
import { filterSubject, fileDownloadByUrl } from "@/core/util/util";
import {
  getGradeList,
  getClassListByLevelAndYear,
} from "@/core/api/school/schoolgradeclass";
import { getObj } from "@/core/api/exam/exam";
export default {
  name: "ExamChooseQuestion",
  components: {
    ExamDetailsHeader,
  },
  data() {
    return {
      //  yearHignOptions yearMiddleOptions
      editQuestionList: [],
      checkQuestion: "",
      yearHignOptions: [],
      yearMiddleOptions: [],
      levelOptions: levelOptions(),
      subjectOptions: subjectOptions,
      examInformation: {},
      chooseQuestionList: [],
      teacherList: [],
      classOptions: [],
      checkTeacher: [],
      btnLoading: false,
      teacherDialogVisible: false,
      assignDialogVisible: false,
      modelSearch: {
        level: "",
        subjectId: "",
        year: "",
        roleId: "",
        classNum: "",
        name: "",
      },
      subjectName: "",
      name: "",
    };
  },
  created() {
    this.examInformation = this.$route.query;
    this.modelSearch.examPaperId = this.examInformation.paperId;
    this.exammarkChooseList();
    this.schoolteacherlistv2();
    this.getSchoolYearList();
    this.getObj();
  },
  methods: {
    //获取考试基本信息
    getObj() {
      getObj(this.$route.query.examId).then((res) => {
        this.name = res.data.data.name;
        res.data.data.paperList.map((item) => {
          if (item.paperId == this.$route.query.paperId) {
            this.subjectName = item.subjectName;
          }
        });
      });
    },
    exportStudent(item, type) {
      // console.log(item);
      let data = {
        examPaperId: item.examPaperId,
        questionNum: item.questionNum,
        type: type,
      };
      this.$message({
        message: "正在导出，请稍后！",
        type: "success",
      });
      exammarkExcelChoose(data).then((res) => {
        fileDownloadByUrl(res.data.data.url, res.data.data.name);
        // console.log(res);
      });
    },
    selectChange() {
      this.schoolteacherlistv2();
    },
    // 学段修改
    levelChange() {
      this.subjectOptions = filterSubject(this.modelSearch.level);
      let filterList = this.subjectOptions.filter(
        (item) => this.modelSearch.subjectId == item.subjectId
      );
      if (filterList.length == 0) {
        this.modelSearch.subjectId = "";
      }
      this.schoolteacherlistv2();
    },
    submitQuestion() {
      // console.log(this.checkQuestion);
      if (!this.checkQuestion) {
        return;
      }
      let data = {
        examPaperId: this.examInformation.paperId,
        questionNum: this.checkQuestion,
        examQuestionGroup: this.indexQuestion.examQuestionGroup,
      };
      this.exammarkChoose(data);
      // questionNum
    },
    submitTeacher() {
      // console.log(this.checkTeacher);
      // if (!this.checkTeacher) {
      //   return;
      // }
      let data = {
        examPaperId: this.examInformation.paperId,
        teacherIds: this.checkTeacher.join(","),
        examQuestionGroup: this.indexQuestion.examQuestionGroup,
      };
      this.exammarkChoose(data);
    },
    exammarkChoose(data) {
      this.btnLoading = true;
      exammarkChoose(data)
        .then(() => {
          this.btnLoading = false;
          this.teacherDialogVisible = false;
          this.assignDialogVisible = false;
          this.$message({
            message: "设置成功",
            type: "success",
          });
          this.exammarkChooseList();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 获取所有年级列表
    getSchoolYearList() {
      getGradeList().then((response) => {
        let grades = response.data.data;
        if (grades && grades.length > 0) {
          grades.forEach((item) => {
            if (item.level === 2) {
              this.yearMiddleOptions.push(item.year);
            } else {
              this.yearHignOptions.push(item.year);
            }
          });
        }
        this.yearHignOptions = [...new Set(this.yearHignOptions)];
        this.yearMiddleOptions = [...new Set(this.yearMiddleOptions)];
      });
    },
    // 获取班级列表
    changeClassOptions() {
      if (this.modelSearch.level && this.modelSearch.year) {
        let query = {
          level: this.modelSearch.level,
          year: this.modelSearch.year,
        };
        getClassListByLevelAndYear(query).then((response) => {
          this.classOptions = response.data.data;
          this.modelSearch.classNum = "";
          if (this.classOptions.length > 0) {
            // this.modelSearch.classNum = this.classOptions[0].id;
            this.schoolteacherlistv2();
          }
        });
      }
    },
    gradeChange() {
      this.changeClassOptions();
    },
    showAssign(question) {
      // console.log(question);
      this.editQuestionList = question.questionNum.split("，");
      this.checkQuestion =
        question.designQuestionNum || this.editQuestionList[0];
      this.indexQuestion = question;
      this.assignDialogVisible = true;
    },
    // 根据搜索条件获取老师列表
    schoolteacherlistv2() {
      let data = {
        ifGetAllTeacher: true,
      };
      schoolteacherlistv2(Object.assign(data, this.modelSearch)).then((res) => {
        // console.log(res);
        this.teacherList = res.data.data;
      });
    },
    showTeacher(question) {
      // console.log(question.handleTeacherId);
      if (question.handleTeacherId) {
        this.checkTeacher = question.handleTeacherId
          .split(",")
          .map((item) => Number(item));
      } else {
        this.checkTeacher = [];
      }

      this.indexQuestion = question;
      this.teacherDialogVisible = true;
    },
    exammarkChooseList() {
      let data = {
        examPaperId: this.examInformation.paperId,
      };
      exammarkChooseList(data).then((res) => {
        this.chooseQuestionList = res.data.data;
        // console.log(res);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ExamChooseQuestion {
  padding: 24px 0 60px;
  .tips {
    background: #fdf0ee;
    border: 1px dashed #ff7575;
    color: #777473;
    padding: 13px;
    span {
      margin-right: 6px;
      padding: 0 6px;
      font-size: 12px;
      letter-spacing: 0;
      border-radius: 50%;
      background: #f17e5e;
      color: #ffffff;
    }
  }
  .teacher-search-box {
    display: flex;
    flex-wrap: wrap;
    .check-teacher-list {
      width: 100%;
      .check-list {
        padding: 2px;
        max-height: 200px;
      }
      .el-checkbox {
        margin-right: 0;
        min-width: 150px;
      }
    }
    .search-item {
      margin-bottom: 24px;
      margin-right: 24px;
      .el-select {
        width: 200px;
      }
    }
    .search-input {
      width: 200px;
      // margin-bottom: 24px;
    }
  }
  .question-tips {
    background: #f2f8fe;
    border: 1px dashed #2474ed;
    padding: 13px 36px;
    position: relative;
    margin-top: 24px;
    color: #737677;
    &::after {
      border-radius: 50%;
      position: absolute;
      color: #ffffff;
      content: "!";
      letter-spacing: 1px;
      background-color: #2474ed;
      width: 13px;
      height: 13px;
      font-size: 12px;
      text-align: center;
      line-height: 13px;
      left: 13px;
      top: 17px;
    }
  }
  .question-list-box {
    margin-top: 24px;
    background-color: #ffffff;
    padding: 24px;
    ::v-deep.el-table .el-table__cell {
      padding: 12px 0;
    }
  }
}
</style>
